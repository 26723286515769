import axios, { AxiosRequestConfig } from 'axios';
import { getCookie, removeCookie } from 'helpers/cookies';

import { endpoint } from './endpoints';
import { CustomErrorAnauthorized } from 'helpers/customErrorApi';

const api = axios.create({
  baseURL: endpoint.urlApi,
  headers: {
    Authorization: getCookie('BACKOFFICE_VEICULOS_TOKEN') ?? '',
  },
  timeout: 30000,
});

const pending: {
  token: string;
  cancel: () => void;
}[] = [];
const CancelToken = axios.CancelToken;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cancelRequest = (config: AxiosRequestConfig<any>) => {
  for (const req of pending) {
    if (req.token === `${config.method ?? ''}:${config.url ?? ''}`) {
      req.cancel();
      const index = pending.findIndex((item) => item === req);
      pending.splice(index, 1);
    }
  }
};

// Add a request interceptor
api.interceptors.request.use((config) => {
  try {
    cancelRequest(config);
    Object.assign(config, {
      cancelToken: new CancelToken((c) => {
        pending.push({
          token: `${config.method ?? ''}:${config.url ?? ''}`,
          cancel: c,
        });
      }),
    });
  } catch (err) {
    console.error(JSON.stringify(err));
  }
  return config;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      removeCookie('BACKOFFICE_VEICULOS_TOKEN');
      window.location.href = '/';
      throw new CustomErrorAnauthorized('Anauthorized');
    }
    return error;
  }
);

export { api };
