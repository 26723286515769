const endpoints = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development': {
      return {
        urlApi: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/dev',
      };
    }
    case 'production': {
      return {
        urlApi: 'https://evxnudn1l1.execute-api.us-east-1.amazonaws.com/prd',
      };
    }
    case 'homologation': {
      return {
        urlApi: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/hml',
      };
    }
    default: {
      return {
        urlApi: 'https://yfpc3a5779.execute-api.us-east-1.amazonaws.com/hml',
      };
    }
  }
};

export const endpoint = endpoints();
